import React, { useEffect, useRef, useState } from "react";
import "./FooterStyles.scss";
import logo from "../assets/GL-3d-logo2.png";
import Contact from "./Contact.js";  
import { useTranslation } from "react-i18next";

function Footer({ alwaysVisible }) {
  const { t } = useTranslation();
  const footerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  // eslint-disable-next-line
  const [isAtBottom, setIsAtBottom] = useState(false);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0
    };

    const observerCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    
    const gallerySection = document.querySelector('.gallery');
    if (gallerySection) {
      observer.observe(gallerySection);
    }

    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      if (windowHeight + scrollTop >= documentHeight - 10) {
        setIsAtBottom(true);
      } else {
        setIsAtBottom(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section id="footer" ref={footerRef} className={`footer ${alwaysVisible || isVisible ? 'visible' : ''}`}>
      <div className="footer__container">
        <div className="footer__content">
          <div className="content-container">
            <div className="footer__logo">
              <img src={logo} alt="Monicle Financial Logo" />
            </div>
            <div className="footer__next-text">
              <div>
                <h2 className="footer__copy">{t("footer.heading")}</h2>
                <p className="footer__copy-en en">
                 {t("footer.text")}
                </p>
              </div>
              <div className="footer__next-area" data-add-line="">
                <a href="/calculate" className="footer__next-link en">
                {t("footer.button-heading")}
                </a>
                <a href="/calculate" className="link -end">
                  <span className="link__text">
                    <span className="en">{t("footer.button-text")}</span>
                  </span>
                  <span className="icon-button-arrow">
                    <span>
                      <i></i>
                      <i></i>
                    </span>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="footer__menu">
            <div className="footer__menu-container">
              <div className="footer__menu-column">
                <h3>{t("footer.sections")}</h3>
                <ul>
                  <li>
                    <a href="/about">{t("footer.sections-text")}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* ------------------------------- */}
        <Contact />
        {/* ------------------------------- */}
        <div className="footer__line-container">
          <div className="footer-line"></div>
          <p className="footer-line__text">
            © 2025 — GLuck Esports. All Rights Reserved.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Footer;