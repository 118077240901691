import React, { useRef, useEffect, useState } from "react";
import { motion, animate, useMotionValue } from "framer-motion";
import "./TickerStyles2.scss";

import image1 from "../assets/carousel-photo-1.jpg";
import image2 from "../assets/carousel-photo-2.jpg";
import image3 from "../assets/carousel-photo-3.jpg";
import image4 from "../assets/carousel-photo-4.jpg";
import image5 from "../assets/carousel-photo-5.jpg";
import image6 from "../assets/carousel-photo-6.jpg";
import image7 from "../assets/carousel-photo-7.jpg";
import image8 from "../assets/carousel-photo-8.jpg";


const Ticker2 = () => {
  const images = [image1, image2, image3, image4, image5, image6, image7, image8];
  const FAST_DURATION = 40;
  const SLOW_DURATION = 80;
  const [duration, setDuration] = useState(FAST_DURATION);
  const containerRef = useRef(null);
  const xTranslation = useMotionValue(0);
  const [mustFinish, setMustFinish] = useState(false);
  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    if (!containerRef.current) return;

    const totalWidth = 500 * images.length; // assuming each image is 500px wide
    let controls;
    let finalPosition = -totalWidth;

    if (mustFinish) {
      controls = animate(xTranslation, [xTranslation.get(), finalPosition], {
        ease: "linear",
        duration: duration * (1 - xTranslation.get() / finalPosition),
        onComplete: () => {
          setMustFinish(false);
          setRerender(!rerender);
        },
      });
    } else {
      controls = animate(xTranslation, [0, finalPosition], {
        ease: "linear",
        duration: duration,
        repeat: Infinity,
        repeatType: "loop",
        repeatDelay: 0,
      });
    }

    return controls?.stop;
  }, [rerender, xTranslation, duration, images.length, mustFinish]);

  return (
    <div className="ticker2-container" ref={containerRef}>
      <div className="ticker2-fade-container">
        <motion.div
          className="ticker2-content"
          style={{
            display: "flex",
            willChange: "transform",
            x: xTranslation
          }}
          onHoverStart={() => {
            setMustFinish(true);
            setDuration(SLOW_DURATION);
          }}
          onHoverEnd={() => {
            setMustFinish(true);
            setDuration(FAST_DURATION);
          }}
        >
          {[...images, ...images].map((image, index) => (
            <div key={`${index}-${image}`} className="ticker2-item">
              <img
                src={image}
                alt={`Ticker item ${index + 1}`}
                style={{ willChange: "transform" }}
              />
            </div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default Ticker2;