import React, { useState, useEffect, useMemo } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useSwipeable } from "react-swipeable";
import "./HistoryStyles.scss";
import { ReactComponent as InfLogo } from "../assets/inf-element-cs.svg";
import { ReactComponent as InfLogo2 } from "../assets/inf-element-valorant.svg";
import { ReactComponent as InfLogo3 } from "../assets/inf-element-dota.svg";
import { ReactComponent as InfLogo4 } from "../assets/inf-element-cod.svg";
import { ReactComponent as InfLogo5 } from "../assets/inf-element-deadlock.svg";
import { ReactComponent as InfLogo6 } from "../assets/inf-element-fifa.svg";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useTranslation } from "react-i18next";
import SlideIn from "./SlideIn.js";

const createEventData = (t) => ({
	"2022": {
		"PARI Night": {
			logo: require("../assets/event-PariNightLogo2.webp"),
			title: "PARI White Night LAN",
			description: t("eventData2022.PARI-Night.description"),
			game: "CS2",
			date: "July 18, 2022 - July 20, 2022",
			prize: "10,000",
		},
		"PARI Patriki": {
			logo: require("../assets/event-PariPatrikiLogo2.webp"),
			title: "PARI Patriki Vibe LAN",
			description: t("eventData2022.PARI-Patriki.description"),
			game: "CS2",
			date: "Sept 12, 2022 - Sept 15, 2022",
			prize: "10,000",
		},
		"PARI Rio": {
			logo: require("../assets/event-PariRioLogo2.webp"),
			title: "PARI After Rio LAN",
			description: t("eventData2022.PARI-Rio.description"),
			game: "CS2",
			date: "Dec 1, 2022 - Dec 4, 2022",
			prize: "10,000",
		},
	},
	"2023": {
		"FME 2-4": {
			logo: require("../assets/event-FonBetLogo2.webp"),
			title: "Fonbet Media Eleague Season 2-4",
			description: t("eventData2023.FME-2-4.description"),
			game: "CS2",
			date: "May 28, 2023 - Aug 20, 2023",
			prize: "20,000",
		},
		"GL LAN": {
			logo: require("../assets/event-GlLanLogo2.webp"),
			title: "GLUCK SPRING LAN 2023",
			description: t("eventData2023.GL-LAN.description"),
			game: "CS2",
			date: "June 10, 2023 - June 11, 2023",
			prize: "5,000",
		},
		"Dunav Party": {
			logo: require("../assets/event-PariDunavLogo2.webp"),
			title: "Dunav Party LAN",
			description: t("eventData2023.Dunav-Party.description"),
			game: "CS2",
			date: "Aug 24, 2023 - Aug 27, 2023",
			prize: "50,000",
		},
		"BB All-Star": {
			logo: require("../assets/event-BbStarLogo.webp"),
			title: "BetBoom All-Star 5x5",
			description: t("eventData2023.BB-All-Star.description"),
			game: "CS2",
			date: "Sept 14, 2023 - Sept 17, 2023",
			prize: "5,000",
		},
		"HP Cup #7": {
			logo: require("../assets/event-HpCupLogo2.webp"),
			title: "Hard Play Cup #7",
			description: t("eventData2023.HP-Cup-#7.description"),
			game: "CS2",
			date: "Sept 22, 2023 - Sept 24, 2023",
			prize: "7,500",
		},
		"FME 5": {
			logo: require("../assets/event-FonBetLogo2.webp"),
			title: "Fonbet Media Eleague Season 5",
			description: t("eventData2023.FME-5.description"),
			game: "CS2",
			date: "Oct 2, 2023 - Oct 7, 2023",
			prize: "20,000",
		},
		"BB 5x5": {
			logo: require("../assets/event-BbStarLogo2.webp"),
			title: "BetBoom ShowMatch 5x5",
			description: t("eventData2023.BB-5x5.description"),
			game: "Valorant",
			date: "Oct 9, 2023 - Oct 9, 2023",
			prize: "20,000",
		},
		Colizeum: {
			logo: require("../assets/event-ColizeumLogo2.webp"),
			title: "Colizeum",
			description: t("eventData2023.Colizeum.description"),
			date: "Oct 12, 2023 - Oct 17, 2023",
		},
		"Copa BB": {
			logo: require("../assets/event-CopaBbLogo2.webp"),
			title: "Copa BetBoom 2023",
			description: t("eventData2023.Copa-BB.description"),
			game: "CS2",
			date: "Oct 21, 2023 - Nov 4, 2023",
			prize: "40,000",
		},
		"HP Cup #8": {
			logo: require("../assets/event-HpCupLogo2.webp"),
			title: "Hard Play Cup #8",
			description: t("eventData2023.HP-Cup-#8.description"),
			game: "CS2",
			date: "Nov 11, 2023 - Nov 12, 2023",
			prize: "7,500",
		},
		"PARI Please": {
			logo: require("../assets/event-PariPleaseLogo2.webp"),
			title: "PARI, Please",
			description: t("eventData2023.PARI-Please.description"),
			game: "CS2",
			date: "Nov 16, 2023 - Nov 20, 2023",
			prize: "100,000",
		},
		"BB Dacha": {
			logo: require("../assets/event-BbDachaEVNLogo2.webp"),
			title: "BetBoom Dacha",
			description: t("eventData2023.BB-Dacha.description"),
			game: "CS2",
			date: "Dec 5, 2023 - Dec 10, 2023",
			prize: "300,000",
		},
		"BB Valorant": {
			logo: require("../assets/event-BbValorantLogo2.webp"),
			title: "BetBoom Showmatch 5x5 Valorant",
			description: t("eventData2023.BB-Valorant.description"),
			game: "Valorant",
			date: "Dec 25, 2023 - Dec 25, 2023",
			prize: "5,000",
		},
	},
	"2024": {
		"Golovin Cup": {
			logo: require("../assets/event-WarzoneCupLogo2.webp"),
			title: "Golovin Warzone Cup",
			description: t("eventData2024.Golovin-Cup.description"),
			game: "COD",
			date: "Jan 6, 2024 - Jan 6, 2024",
			prize: "6,500",
		},
		"BB Battle 5": {
			logo: require("../assets/event-BbStreamersLogo2.webp"),
			title: "BetBoom Streamers Battle 5",
			description: t("eventData2024.BB-Battle-5.description"),
			game: "Dota 2",
			date: "Jan 22, 2024 - Jan 28, 2024",
			prize: "23,000",
		},
		RES: {
			logo: require("../assets/event-RESLogo2.webp"),
			title: "RES Regional Champions 2024",
			description: t("eventData2024.RES.description"),
			game: "CS2",
			date: "Feb 15, 2024 - Oct 19, 2024",
			prize: "250,000",
		},
		"BB League": {
			logo: require("../assets/event-BbValorant24Logo2.webp"),
			title: "BetBoom VALORANT League",
			description: t("eventData2024.BB-League.description"),
			game: "Valorant",
			date: "Mar 9, 2024 - Mar 10, 2024",
			prize: "7,000",
		},
		FME: {
			logo: require("../assets/event-FonBetLogo2.webp"),
			title: "Fonbet Media Eleague Season 6",
			description: t("eventData2024.FME.description"),
			game: "CS2",
			date: "Apr 1, 2024 - Apr 7, 2024",
			prize: "22,000",
		},
		ROFLOTURIK: {
			logo: require("../assets/event-RoflCupLogo2.webp"),
			title: t("eventData2024.ROFLOTURIK.title"),
			description: t("eventData2024.ROFLOTURIK.description"),
			game: "CS2",
			date: "Apr 19, 2024 - Apr 21, 2024",
			prize: "10,000",
		},
		CCT: {
			logo: require("../assets/event-CCTS2Logo2.webp"),
			title: "CCT Season 2",
			description: t("eventData2024.CCT.description"),
			game: "CS2",
			date: "Apr 15, 2024 - Now",
			prize: "50,000",
		},
		"BB Dacha": {
			logo: require("../assets/event-BbDachaBEGLogo2.webp"),
			title: "BetBoom Dacha Belgrade 2024",
			description: t("eventData2024.BB-Dacha.description"),
			game: "CS2",
			date: "May 14, 2024 - May 19, 2024",
			prize: "500,000",
		},
		RCA: {
			logo: require("../assets/event-RCALogo2.webp"),
			title: "Regional Clash Arena",
			description: t("eventData2024.RCA.description"),
			game: "CS2",
			date: "March 25, 2024 - June 17, 2024",
			prize: "180,000",
		},
		Aunkere: {
			logo: require("../assets/event-AunkereCupLogo2.webp"),
			title: "Aunkere Cup",
			description: t("eventData2024.Aunkere.description"),
			game: "CS2",
			date: "June 21, 2024 - June 23, 2024",
			prize: "24,000",
		},
		"BB League SU": {
			logo: require("../assets/event-BbValorantSmLogo2.webp"),
			title: "BetBoom VALORANT League: Summer",
			description: t("eventData2024.BB-League-SU.description"),
			game: "Valorant",
			date: "June 24, 2024 - June 29, 2024",
			prize: "12,000",
		},
		"FME Dota2": {
			logo: require("../assets/event-FMEDota22.webp"),
			title: "Fonbet Media Eleague Dota 2",
			description: t("eventData2024.FME-Dota2.description"),
			game: "Dota 2",
			date: "Aug 22, 2024 - Aug 27, 2024",
			prize: "22,000",
		},
		"BB Dacha #2": {
			logo: require("../assets/event-BbDachaBEG2Logo2.webp"),
			title: "BetBoom Dacha Belgrade 2024 #2",
			description: t("eventData2024.BB-Dacha-#2.description"),
			game: "CS2",
			date: "Aug 24, 2024 - Sep 01, 2024",
			prize: "505,000",
		},
		"Recrent Cup": {
			logo: require("../assets/event-RecrentCup2.webp"),
			title: "RECRENT PARI CUP DOTA 2",
			description: t("eventData2024.Recrent-Cup.description"),
			game: "Dota 2",
			date: "Aug 29, 2024 - Aug 29, 2024",
			prize: "10,000",
		},
		DEADLOCK: {
			logo: require("../assets/event-DeadLockCup2.webp"),
			title: "BetBoom DEADLOCK CUP",
			description: t("eventData2024.DEADLOCK.description"),
			game: "Deadlock",
			date: "Sep 03, 2024 - Sep 03, 2024",
			prize: "6,800",
		},
		"CSGORUN S1": {
			logo: require("../assets/event-CSGORUN2.webp"),
			title: "FASTCUP: CSGORUN Cup Season 1",
			description: t("eventData2024.CSGORUN-S1.description"),
			game: "CS2",
			date: "Sep 14, 2024 - Sep 24, 2024",
			prize: "55,000",
		},
		"DEADLOCK #2": {
			logo: require("../assets/event-DeadLockCup2.webp"),
			title: "BetBoom DEADLOCK CUP #2",
			description: t("eventData2024.DEADLOCK-#2.description"),
			game: "Deadlock",
			date: "Sep 21, 2024 - Sep 22, 2024",
			prize: "10,000",
		},
		"BB League FL": {
			logo: require("../assets/event-BbValorantSmLogo2.webp"),
			title: "BetBoom VALORANT League: Fall",
			description: t("eventData2024.BB-League-FL.description"),
			game: "Valorant",
			date: "Sep 30, 2024 - Oct 05, 2024",
			prize: "10,000",
		},
		"BB All-Star": {
			logo: require("../assets/event-BBAllStar2x22.webp"),
			title: "BetBoom All-Star 2x2 #2",
			description: t("eventData2024.BB-All-Star.description"),
			game: "CS2",
			date: "Oct 11, 2024 - Oct 12, 2024",
			prize: "10,000",
		},
		"FME 7": {
			logo: require("../assets/event-FonBetLogo2.webp"),
			title: "Fonbet Media Eleague Season 7",
			description: t("eventData2024.FME-7.description"),
			game: "CS2",
			date: "Oct 23, 2024 - Oct 29, 2024",
			prize: "20,000",
		},
		"DEADLOCK #3": {
			logo: require("../assets/event-DeadLockCup2.webp"),
			title: "BetBoom DEADLOCK CUP #3",
			description: t("eventData2024.DEADLOCK-#3.description"),
			game: "Deadlock",
			date: "Nov 14, 2024 - Nov 15, 2024",
			prize: "10,000",
		},
		"FME 8": {
			logo: require("../assets/event-FMEDeadlock.png"),
			title: "Fonbet Media Eleague DEADLOCK",
			description: t("eventData2024.FME-DEADLOCK.description"),
			game: "Deadlock",
			date: "Dec 23, 2024 - Dec 24, 2024",
			prize: "10,000",
		},
		"Aunkere #2": {
			logo: require("../assets/event-BB-aunkerecup-2.png"),
			title: "BetBoom Aunkere Cup 2x2",
			description: t("eventData2024.Aunkere-2x2.description"),
			game: "CS2",
			date: "Dec 27, 2024 - Dec 28, 2024",
			prize: "10,000",
		},
	},
	"2025": {
		"Woman Cup": {
			logo: require("../assets/event-WomanCup.png"),
			title: "BetBoom Woman Cup",
			description: t("eventData2025.Woman-cup.description"),
			game: "CS2",
			date: "Jan 9, 2025 - Jan 11, 2025",
			prize: "10,000",
		},
		LanDaLan: {
			logo: require("../assets/event-LanDaLan.png"),
			title: "BetBoom LanDaLan",
			description: t("eventData2025.LanDaLan.description"),
			game: "CS2",
			date: "Feb 10, 2025 - Feb 13, 2025",
			prize: "10,000",
		},
		"MelBet ML": {
			logo: require("../assets/event-Melbet.png"),
			title: "MelBet Media League",
			description: t("eventData2025.Melbet.description"),
			game: "CS2",
			date: "Feb 16, 2025 - Mar 16, 2025",
			prize: "20,000",
		},
		"FC CUP SE2": {
			logo: require("../assets/event-FC-cup2.png"),
			title: "BetBoom FC Streamers Cup SE 2",
			description: t("eventData2025.FC-CUP.description"),
			game: "FC 25",
			date: "Mar 26, 2025 - Mar 28, 2025",
			prize: "5,000",
		},
		"FME Dota2": {
			logo: require("../assets/event-FMEDota22.webp"),
			title: "Fonbet Media Eleague Dota 2 SE2",
			description: t("eventData2025.FME-Dota2.description"),
			game: "Dota 2",
			date: "Mar 28, 2025 - Apr 2, 2025",
			prize: "24,000",
		},
	},
});
function History() {
	const { t } = useTranslation();
	const allEventData = useMemo(() => createEventData(t), [t]);

	const [activeYear, setActiveYear] = useState("2025");
	const [activeEvent, setActiveEvent] = useState("");
	const [swipePosition, setSwipePosition] = useState(0);

	useEffect(() => {
		const eventsForYear = allEventData[activeYear];
		if (eventsForYear && !activeEvent) {
			const firstEventKey = Object.keys(eventsForYear)[0];
			setActiveEvent(firstEventKey);
		}
	}, [activeYear, allEventData, activeEvent]);
	

	const events = allEventData[activeYear]
		? Object.keys(allEventData[activeYear])
		: [];

	const handleYearClick = (year) => {
		setActiveYear(year);
	};

	const getGameLogo = (game) => {
		switch (game) {
			case "CS2":
				return <InfLogo />;
			case "Valorant":
				return <InfLogo2 />;
			case "Dota 2":
				return <InfLogo3 />;
			case "COD":
				return <InfLogo4 />;
			case "Deadlock":
				return <InfLogo5 />;
			case "FC 25":
				return <InfLogo6 />;
			default:
				return null;
		}
	};
	const handleSwipe = (direction) => {
		setHasInteracted(true);
		const currentIndex = events.indexOf(activeEvent);
		if (direction === "LEFT" && currentIndex < events.length - 1) {
			setSwipePosition(-100);
			setTimeout(() => {
				setActiveEvent(events[currentIndex + 1]);
				setSwipePosition(0);
			}, 300);
		} else if (direction === "RIGHT" && currentIndex > 0) {
			setSwipePosition(100);
			setTimeout(() => {
				setActiveEvent(events[currentIndex - 1]);
				setSwipePosition(0);
			}, 300);
		}
	};

	const swipeHandlers = useSwipeable({
		onSwipedLeft: () => handleSwipe("LEFT"),
		onSwipedRight: () => handleSwipe("RIGHT"),
		preventDefaultTouchmoveEvent: false,
		trackMouse: true,
	});

	const [hasInteracted, setHasInteracted] = useState(false);

	return (
		<section id="history" className="history">
			<div className="history-section">
				<div className="history-heading">
					<SlideIn direction="up" delay={100} threshold={0.1}>
						<h2 className="tracking-in-expand">{t("history.title")}</h2>
					</SlideIn>
				</div>
				<SlideIn direction="up" delay={150} threshold={0.1}>
					<div className="history-calendar">
						<div className="calendar-years">
							<ul className="years">
								{["2022", "2023", "2024", "2025"].map((year) => (
									<li
										key={year}
										className={activeYear === year ? "active" : ""}
										onClick={() => handleYearClick(year)}
									>
										{year}
									</li>
								))}
							</ul>
						</div>
						<div className="calendar-events">
							<ul className="events">
								{events.map((event) => (
									<li key={event}>
										<button
											className={`button-events ${
												activeEvent === event ? "active" : ""
											} ${
												[
													"ROFLOTURIK",
													"BB League SU",
													"DEADLOCK",
													"CSGORUN S1",
													"DEADLOCK #2",
													"BB League FL",
													"DEADLOCK #3",
												].includes(event)
													? "small-font"
													: ""
											}`}
											onClick={() => setActiveEvent(event)}
										>
											{event}
										</button>
									</li>
								))}
							</ul>
						</div>
						<TransitionGroup>
							<CSSTransition
								key={activeEvent}
								classNames="slide"
								timeout={400}
								unmountOnExit={false}
							>
								<div
									className={`calendar-description ${
										hasInteracted ? "interacted" : ""
									}`}
								>
									{activeEvent &&
										allEventData[activeYear] &&
										allEventData[activeYear][activeEvent] && (
											<>
												<figure style={{ borderRadius: "32px", opacity: 1 }}>
													<div
														{...swipeHandlers}
														className="description-image"
														style={{
															transform: `translateX(${swipePosition}%)`,
														}}
													>
														<img
															src={allEventData[activeYear][activeEvent].logo}
															alt={allEventData[activeYear][activeEvent].title}
														/>
													</div>
												</figure>
												<article className="article-description">
													<div className="description-inf">
														{allEventData[activeYear][activeEvent].game && (
															<div className="inf-element">
																<div className="icon-container">
																	{getGameLogo(
																		allEventData[activeYear][activeEvent].game
																	)}
																</div>
																<div className="inf-text p12">
																	{allEventData[activeYear][activeEvent].game}
																</div>
															</div>
														)}
														<div className="inf-element">
															<div className="icon-container">
																<CalendarViewMonthIcon />
															</div>
															<div className="inf-text p12">
																{allEventData[activeYear][activeEvent].date}
															</div>
														</div>
														{allEventData[activeYear][activeEvent].prize && (
															<div className="inf-element">
																<div className="icon-container">
																	<AttachMoneyIcon />
																</div>
																<div className="inf-text p12">
																	{allEventData[activeYear][activeEvent].prize}
																</div>
															</div>
														)}
													</div>
													<div className="description-text">
														<p className="description-text-link">
															{allEventData[activeYear][activeEvent].title}
														</p>
														<p className="description-text-p">
															{
																allEventData[activeYear][activeEvent]
																	.description
															}
														</p>
													</div>
												</article>
											</>
										)}
								</div>
							</CSSTransition>
						</TransitionGroup>
					</div>
				</SlideIn>
			</div>
		</section>
	);
}

export default History;
