import React from "react";
import Slider from "react-slick";
import CarouselPhoto1 from "../assets/carousel-photo-1.jpg";
import CarouselPhoto2 from "../assets/carousel-photo-2.jpg";
import CarouselPhoto3 from "../assets/carousel-photo-3.jpg";
import CarouselPhoto4 from "../assets/carousel-photo-4.jpg";
import CarouselPhoto5 from "../assets/carousel-photo-5.jpg";
import CarouselPhoto6 from "../assets/carousel-photo-6.jpg";
import CarouselPhoto7 from "../assets/carousel-photo-7.jpg";
import CarouselPhoto8 from "../assets/carousel-photo-8.jpg";
import Ticker2 from "./Ticker2";
import "./GalleryStyles.scss";
import SlideIn from "./SlideIn.js";
import { useTranslation } from "react-i18next";

function Gallery() {
	const { t } = useTranslation();
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: true,
		centerPadding: "10px",
		autoplay: false,
		arrows: false,
	};

	return (
		<section className="gallery">
			<div className="gallery-container">
				<div className="gallery-heading">
					<SlideIn direction="up" delay={100} threshold={0.1}>
						<h1>{t("gallery.title")}</h1>
					</SlideIn>
					<SlideIn direction="up" delay={100} threshold={0.2}>
						<p className="gallery-text">{t("gallery.text")}</p>
					</SlideIn>
					<div className="gallery-line"></div>
				</div>
				<div className="gallery-carousel mobile-only">
					<Slider {...settings}>
						<div>
							<img src={CarouselPhoto1} alt="Event 1" />
						</div>
						<div>
							<img src={CarouselPhoto2} alt="Event 2" />
						</div>
						<div>
							<img src={CarouselPhoto3} alt="Event 3" />
						</div>
						<div>
							<img src={CarouselPhoto4} alt="Event 4" />
						</div>
						<div>
							<img src={CarouselPhoto5} alt="Event 5" />
						</div>
						<div>
							<img src={CarouselPhoto6} alt="Event 6" />
						</div>
						<div>
							<img src={CarouselPhoto7} alt="Event 7" />
						</div>
						<div>
							<img src={CarouselPhoto8} alt="Event 8" />
						</div>
					</Slider>
				</div>
			</div>
			<div className="desktop-only"> 
				<Ticker2 speed={40} />
			</div>
		</section>
	);
}

export default Gallery;
